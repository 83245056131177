<template>
  <div>
    <div class="chisel48">
      <div class="chisel-content">
        <div class="title">{{ item.title }}</div>
        <div style="margin:10px 0 20px 0;" class="desc">{{ item.remark }}</div>
        <div v-for="(param,pIdx) of JSON.parse(item.parameter)" :key="pIdx" style="margin-top: 10px;" class="desc">
          {{ param.value }}
        </div>
      </div>
      <img :src="fileBaseUrl + item.imageUrl" class="chisel-content-img" />
    </div>
    <div class="btn-container">
      <a-button class="home-btn c" @click="customizedVisible=true">{{ $t('home.customized') }}</a-button>
      <a-button class="home-btn m" @click="go2">{{ $t('common.more') }}</a-button>
    </div>
    <Customized :show="customizedVisible" @hidden="customizedVisible=false"></Customized>
  </div>
</template>

<script>
import Customized from './Customized'
export default {
  components: { Customized },
  props: {
    source: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      fileBaseUrl: this.$baseUrl,
      customizedVisible: false
    }
  },
  computed: {
    item () {
      const data = this.source
      data.params = JSON.parse(data.parameter)
      return data
    }
  },
  methods: {
    go2 () {
      this.$router.push({ name: 'ChiselDetail', query: { id: this.source.productId } })
    }
  }
}
</script>
<style lang="less" scoped>
.chisel48 {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .chisel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    word-break: break-word;

    color: #FFFFFF;
    font-weight: bold;
    .title {
      font-size: 34px;
    }

    .desc {
      background: rgba(0,0,0,0.3);
      border-radius: 8px;
      padding: 0 8px;
    }
  }
}

.btn-container {
  position: absolute;
  bottom: 0;
  transform: translateY(-100%-100px);
  width: 100%;
  display: flex;
  justify-content: center;

  .c {
    background: #333333;
    color: #FFFFFF;
  }

  .m {
    margin-left: 10px;
    background: #E8E8E8;
    color: #333333;;
  }
}

@media screen and (min-width:1024px) {
  .chisel-content {
    margin: 0 120px 0 20%;
  }

  .chisel-content-img {
    width: 70px;
  }
}

@media screen and (max-width:1000px) {
  .chisel48 {
    width: 85%;
  }

  .chisel-content {
    margin: 0 10%;
  }

  .chisel-content-img {
    width: 40px;
  }
}
</style>
