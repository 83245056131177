<template>
  <Carousel class="carousel-container" dot-position="left" :dots="false" :infinite="false" ref="carouselRef" v-if="showCarousel">
    <div class="bg-item"
         v-for="(item,idx) of dataList"
         :key="idx"
         :class="[`bg-${idx+1}-container`]"
         :style="{ backgroundImage: `url(${fileBaseUrl}${isMobile?item.phoneBackImageUrl:item.backImageUrl})` }">
      <template v-if="idx==0">
        <Product :source="item" @next="$refs.carouselRef.next()"></Product>
      </template>
      <template v-if="idx==1||idx==2||idx==3">
        <Chisel48A :source="item"></Chisel48A>
      </template>
      <template v-if="idx==4">
        <OtherProduct :source="item"></OtherProduct>
      </template>
      <template v-if="idx==5">
        <ProductProcess :source="item"></ProductProcess>
      </template>
      <template v-if="idx==6">
        <Service :source="item"></Service>
      </template>
    </div>
  </Carousel>
</template>
<script>
import deviceMixin from '@/store/device-mixin'
import Product from './modules/home/Product'
import Chisel48A from './modules/home/Chisel48A'
import OtherProduct from './modules/home/OtherProduct'
import ProductProcess from './modules/home/ProductProcess'
import Service from './modules/home/Service'

export default {
  name: 'Home',
  mixins: [deviceMixin],
  components: {
    Product,
    Chisel48A,
    OtherProduct,
    ProductProcess,
    Service
  },
  data () {
    return {
      fileBaseUrl: this.$baseUrl,
      showCarousel: false,
      dataList: []
    }
  },
  created () {
    this.$home.homeList().then((res) => {
      this.showCarousel = true
      this.dataList = res.rows || []
    })
  }
}
</script>
<style lang="less" scoped>
.carousel-container {
  height: 100vh;
  & /deep/ .slick-slide {
    border: unset;
    height: 100vh;
  }
}
.bg-item {
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex!important;
  flex-direction: column;
  justify-content: center;
  position: relative;

  /deep/ .home-btn {
    border: none;
    border-radius: 6px;
    width: 150px;
    font-size: 10px;
    font-weight: bold;
  }
}

@media screen and (max-width:1000px) {
  .bg-1-container {
    flex-wrap: wrap;
    align-content: center;
  }
}
</style>
