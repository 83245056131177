<template>
  <div class="service">
    <div class="service-content">
      <div class="title">{{ item.title }}</div>
      <div v-for="(param,pIdx) of item.params" :key="pIdx" class="desc-container">
        <div style="margin-top: 10px;">
          {{ param.value }}
        </div>
      </div>
      <a-button class="home-btn" @click="go2">{{ $t('common.more') }}</a-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    item () {
      const data = this.source
      data.params = JSON.parse(data.parameter)
      return data
    }
  },
  methods: {
    go2 () {
      this.$router.push({ name: 'Service' })
    }
  }
}
</script>
<style lang="less" scoped>
.service {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.service-content {
  width: 50%;
  height: 100%;
  background: linear-gradient(90deg, rgba(52,52,52,0) 0,rgba(52,52,52,0.65) 100%);
  text-align: center;
  color: #FFFFFF;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    width: 80%;
    font-size: 27px;
  }

  .desc-container {
    width: 40%;
  }

  .home-btn {
    background: #333333;
    color: #FFFFFF;
    margin-top: 20px;
  }
}

@media screen and (max-width:1000px) {
  .service-content {
    width: 100%;
  }
}
</style>
