<template>
  <div class="product">
    <div class="product-content" v-for="(param,pIdx) of item.params" :key="pIdx">
      <div>{{ param.name }}</div>
      <div style="margin-top: 10px;">
        {{ param.value }}
      </div>
    </div>
    <div class="arrow-container1" @click="$emit('next')">
      <a-icon type="down" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    item () {
      const data = this.source
      data.params = JSON.parse(data.parameter)
      return data
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.arrow-container1 {
  bottom: 60px;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  position: absolute;
  -webkit-animation: upToDownMove 2s infinite;
  animation: upToDownMove 2s infinite;
  cursor: pointer
}

.product {
  display: flex;
  justify-content: center;
  text-align: center;

  .product-content {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 900;
    position: absolute;
    bottom: 120px;
  }
}
</style>
