<template>
  <div class="process">
    <div class="process-content">
      <div class="title">{{ item.title }}</div>
      <div v-for="(param,pIdx) of item.params" :key="pIdx" class="desc-container">
        <div class="name" style="margin: 10px; 0">{{ param.name }}</div>
        <div>
          {{ param.value }}
        </div>
      </div>
      <a-button class="home-btn" @click="go2">{{ $t('common.more') }}</a-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    item () {
      const data = this.source
      data.params = JSON.parse(data.parameter)
      return data
    }
  },
  methods: {
    go2 () {
      this.$router.push({ name: 'ProdTech' })
    }
  }
}
</script>
<style lang="less" scoped>
.process {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.process-content {
  width: 50%;
  height: 100%;
  background: linear-gradient(90deg, rgba(52,52,52,0) 0,rgba(52,52,52,0.65) 100%);
  text-align: center;
  color: #FFFFFF;
  font-weight: bold;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    width: 80%;
    font-size: 27px;
  }

  .desc-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .name {
      font-size: 16px;
      background: rgba(0,0,0,0.3);
      border-radius: 8px;
      padding: 0 8px;
    }
  }

  .home-btn {
    background: #333333;
    color: #FFFFFF;
    margin-top: 20px;
  }
}

@media screen and (max-width:1000px) {
  .process-content {
    width: 100%;
  }
}
</style>
