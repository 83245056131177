<template>
  <div class="otherParts">
    <div class="otherParts-content">
      <div class="title">{{ item.title }}</div>
      <div v-for="(param,pIdx) of item.params" :key="pIdx" style="margin-top: 10px;" class="desc">
        {{ param.value }}
      </div>
    </div>
    <div class="btn-container">
      <a-button class="home-btn c" @click="customizedVisible=true">{{ $t('home.customized') }}</a-button>
      <a-button class="home-btn m" @click="go2">{{ $t('common.more') }}</a-button>
    </div>
    <Customized :show="customizedVisible" @hidden="customizedVisible=false"></Customized>
  </div>
</template>

<script>
import Customized from './Customized'
export default {
  components: { Customized },
  props: {
    source: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    item () {
      const data = this.source
      data.params = JSON.parse(data.parameter)
      return data
    }
  },
  data () {
    return {
      customizedVisible: false
    }
  },
  methods: {
    go2 () {
      this.$router.push({ name: 'OtherParts' })
    }
  }
}
</script>
<style lang="less" scoped>
.otherParts {
  display: flex;
  justify-content: center;

  .otherParts-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
    font-weight: bold;

    .title {
      font-size: 34px;
    }

    .desc {
      background: rgba(0,0,0,0.3);
      border-radius: 8px;
      padding: 0 8px;
    }
  }

  .btn-container {
    position: absolute;
    bottom: 0;
    transform: translateY(-100%-100px);
    width: 100%;
    display: flex;
    justify-content: center;

    .c {
      background: #333333;
      color: #FFFFFF;
    }

    .m {
      margin-left: 10px;
      background: #E8E8E8;
      color: #333333;;
    }
  }
}
</style>
