<template>
  <div>
    <a-modal :visible="visible" @cancel="close()" destroy-on-close>
      <div slot="title" class="title">
        {{ $t('home.customized') }}
      </div>
      <div class="text-center text-sm font-bold mb-4">{{ $t('home.customized.tip') }}</div>
      <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }" ref="ruleForm" :model="formModel" :rules="rules">
        <a-form-model-item :label="$t('home.customized.userName')" prop="lastName">
          <a-input v-model="formModel.lastName" />
        </a-form-model-item>
        <a-form-model-item :label="$t('home.customized.tel')" prop="tel">
          <a-input v-model="formModel.tel" />
        </a-form-model-item>
        <a-form-model-item :label="$t('home.customized.email')" prop="email">
          <a-input v-model="formModel.email" />
        </a-form-model-item>
        <a-form-model-item :label="$t('home.customized.note')" prop="note">
          <a-input v-model="formModel.note" type="textarea" />
        </a-form-model-item>
      </a-form-model>
      <div slot="footer" class="footer">
        <a-button type="primary" @click="submit()">{{ $t('home.customized.submit') }}</a-button>
        <a-button @click="close()">{{ $t('home.customized.cancel') }}</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show: {
      immediate: true,
      handler (show) {
        this.visible = show
      }
    }
  },
  data () {
    return {
      visible: true,
      formModel: {},
      rules: {
        lastName: [
          { required: true, message: this.$t('home.customized.validate-require'), trigger: 'change' }
        ],
        tel: [
          { required: true, message: this.$t('home.customized.validate-require'), trigger: 'change' },
          { pattern: new RegExp('(^1\\d{10}$)|(^(0\\d{2,3}-?)?\\d{7,8}$)'), message: this.$t('home.customized.validate-format'), trigger: 'change' }
        ],
        email: [
          { required: true, message: this.$t('home.customized.validate-require'), trigger: 'change' },
          { type: 'email', message: this.$t('home.customized.validate-format'), trigger: 'change' }
        ],
        note: [
          { required: true, message: this.$t('home.customized.validate-require'), trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    close () {
      this.visible = false
      this.$emit('hidden', this.visible)
    },
    submit () {
      this.$refs.ruleForm.validate(valid => {
        // valid 当rules里的错误信息配置为空字符串时，会失效
        if (valid) {
          this.$home.order(this.formModel).then(() => {
            this.$message.success(this.$t('common.success'))
            this.formModel = {}
            this.close()
          })
        } else {
          console.log('error submit!!')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-content {
  border-radius: 10px;
}

/deep/ .ant-modal-header {
  background: #376E25;
}

/deep/ .ant-modal-close-x{
  color: #FFFFFF;
}

/deep/ .ant-btn-primary {
  background: #376E25;
  border-color: unset
}

.title {
  color: #FFFFFF;
}

.footer {
  display: flex;
  justify-content: center;
}
</style>
